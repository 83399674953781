<template>
  <div class="gallery-small-picrow">
    <GallerySmallTnail
      v-for="(img, i) in imgArr"
      :key="img.alt"
      :img="img"
      :idx="i"
      :highlight="i === shownIdx"
      :want-ratio="wantRatio"
      @click.native="$emit('gallery-small-picrow-click', i)"
      @gallery-small-tnail-newratio="onTnailNewratio"
    />
  </div>
</template>

<script>
import GallerySmallTnail from "@/components/GallerySmallTnail.vue"
import ImgData from "@/models/ImgData.js"

export default {
  components: {
    GallerySmallTnail
  },

  props: {
    imgArr: {
      type: Array,
      required: true,
      validator: arr => arr.every(x => x instanceof ImgData)
    },
    shownIdx: {
      type: Number,
      required: true
    },
    wantRatio: {
      type: Number,
      required: true
    }
  },

  methods: {
    onTnailNewratio(ratio) {
      this.$emit("gallery-small-picrow-newratio", ratio)
    }
  }
}
</script>

<styles lang="scss">
@use "sass:math";

@use "img";
@use "vars";

.gallery-small-picrow {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: auto;
  grid-gap: math.div(img.$gap, 2);
  background-color: img.$gap__color;
  padding-bottom: img.$gap;
}

.gallery-small-picrow__item {
  background-color: vars.$brown--black;
}
</styles>
