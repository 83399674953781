<template>
  <div
    class="gallery-small-tnail"
  >
    <ImgCut
      :src="img.src"
      :alt="img.alt"
      :want-ratio="wantRatio"
      @img-cut-newratio="onImgCutNewratio"
    />
    <div
      class="gallery-small-tnail__overlay"
      :class="{'gallery-small-tnail__overlay--highlight': highlight}"
    >
      <MoonBg
        :moon-opacity="moonOpacity"
      >
        <div class="gallery-small-tnail__number">
          <span>{{ idx + 1 }}</span>
        </div>
      </MoonBg>
    </div>
  </div>
</template>

<script>
import ImgCut from "@/components/ImgCut.vue"
import ImgData from "@/models/ImgData.js"
import MoonBg from "@/components/MoonBg.vue"

export default {
  components: {
    ImgCut,
    MoonBg
  },

  props: {
    highlight: {
      type: Boolean,
      default: false
    },
    idx: {
      type: Number,
      required: true
    },
    img: {
      type: ImgData,
      required: true
    },
    wantRatio: {
      type: Number,
      required: true
    }
  },

  computed: {
    moonOpacity() {
      return this.highlight ? 0.9 : 0.7
    }
  },

  methods: {
    onImgCutNewratio(ratio) {
      this.$emit("gallery-small-tnail-newratio", ratio)
    }
  }
}
</script>

<styles lang="scss">
@use "img";
@use "position";
@use "vars";
@use "z-index";

.gallery-small-tnail {
  position: relative;
  cursor: pointer;
}

.gallery-small-tnail__overlay {
  position: absolute;
  z-index: z-index.$gallery-small-tnail__overlay;
  @include position.all-zeros;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.25em;
  background-color: img.$overlay--black;
}

.gallery-small-tnail__overlay--highlight {
  background-color: img.$overlay--beige;
}

.gallery-small-tnail__number {
  $d: 1.8em;
  width: $d;
  height: $d;
  display: flex;
  align-items: center;
  justify-content: center;
  color: vars.$brown--black;

  span {
    font-family: vars.$font-fam-deco;
  }
}
</styles>
