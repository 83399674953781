<template>
  <div
    class="gallery-big-main"
    :style="style"
  >
    <div class="gallery-big-main__overlay">
      <MoonBg
        class="gallery-big-main__fwd"
        @click.native="$emit('gallery-big-main-fwd')"
      >
        <NucleoGLoop82 />
      </MoonBg>
    </div>
  </div>
</template>

<script>
import ImgData from "@/models/ImgData.js"
import MoonBg from "@/components/MoonBg.vue"
import NucleoGLoop82 from "@/components/svg/NucleoGLoop82.vue"

export default {
  components: {
    MoonBg,
    NucleoGLoop82
  },

  props: {
    imgData: {
      type: ImgData,
      required: true
    }
  },

  computed: {
    style() {
      return {
        backgroundImage: `url('${this.imgData.src}')`,
        backgroundPosition: this.imgData.opts.bgPos
      }
    }
  }
}
</script>

<styles lang="scss">
@use "bgimg";
@use "svg";
@use "vars";
@use "z-index";

.gallery-big-main {
  position: relative;
  @include bgimg.standard;
}

.gallery-big-main__overlay {
  position: absolute;
  z-index: z-index.$gallery-big-main__overlay;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  padding: 0.5em;
}

.gallery-big-main__fwd {
  $d: 3em;
  width: $d;
  height: $d;
  padding: 0.75em;
  cursor: pointer;

  svg {
    width: 100%;
    height: 100%;
    @include svg.nucleo-g-loop-82(vars.$brown--black);
  }
}
</styles>
