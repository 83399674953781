<template>
  <div class="gallery-big">
    <GalleryBigMain
      class="gallery-big__main"
      :img-data="imgs.main"
      @gallery-big-main-fwd="fwdImgIdxMain"
    />
    <GalleryBigCut
      v-for="n in cutNumbers"
      :key="n"
      :class="`gallery-big__cut${n}`"
      :img-data="imgs[`cut${n}`]"
      :number="n"
      @click.native="focusImg(imgs[`cut${n}`])"
    />
  </div>
</template>

<script>
import {fwdIdxCircular} from "@/util/array.js"
import GalleryBigMain from "@/components/GalleryBigMain.vue"
import GalleryBigCut from "@/components/GalleryBigCut.vue"
import ImgData from "@/models/ImgData.js"
import {range} from "lodash-es"

export default {
  components: {
    GalleryBigMain,
    GalleryBigCut
  },

  props: {
    imgArr: {
      type: Array, // ImgData[]
      required: true,
      validator: arr => arr.every(x => x instanceof ImgData)
    }
  },

  data() {
    return {
      cutNumbers: range(1, 5),
      imgIdxMain: 0
    }
  },

  computed: {
    imgs() {
      const len = this.imgArr.length
      return {
        main: this.imgArr[this.imgIdxMain],
        cut1: this.imgArr[fwdIdxCircular(this.imgIdxMain, 1, len)],
        cut2: this.imgArr[fwdIdxCircular(this.imgIdxMain, 2, len)],
        cut3: this.imgArr[fwdIdxCircular(this.imgIdxMain, 3, len)],
        cut4: this.imgArr[fwdIdxCircular(this.imgIdxMain, 4, len)]
      }
    }
  },

  watch: {
    imgArr() {
      this.imgIdxMain = 0 // jump to first pic
    }
  },

  methods: {
    /** @param {ImgData} img */
    focusImg(img) {
      this.imgIdxMain = this.imgArr.indexOf(img)
    },

    fwdImgIdxMain() {
      this.imgIdxMain = fwdIdxCircular(this.imgIdxMain, 1, this.imgArr.length)
    }
  }
}
</script>

<styles lang="scss">
@use "gallery-big";
@use "img";

.gallery-big {
  display: grid;
  $row-h: minmax(16em, 40vh);
  $cut-w: minmax(gallery-big.$cut-min-w, 1fr);
  grid-template:
    "cut1 main cut3" $row-h
    "cut2 main cut4" #{$row-h} /
    $cut-w 18fr $cut-w;
  grid-gap: img.$gap;
  padding: img.$gap 0;
  background-color: img.$gap__color;
}

.gallery-big__main {
  grid-area: main;
}

.gallery-big__cut1 {
  grid-area: cut1;
}

.gallery-big__cut2 {
  grid-area: cut2;
}

.gallery-big__cut3 {
  grid-area: cut3;
}

.gallery-big__cut4 {
  grid-area: cut4;
}
</styles>
