<template>
  <div
    class="gallery-big-cut"
    :style="styles"
  >
    <div
      class="gallery-big-cut__overlay"
      :style="stylesOverlay"
    >
      <MoonBg
        class="gallery-big-cut__moon"
        :moon-opacity="0.7"
      >
        <div class="gallery-big-cut__number">
          <span>{{ number }}</span>
        </div>
      </MoonBg>
    </div>
  </div>
</template>

<script>
import ImgData from "@/models/ImgData.js"
import MoonBg from "@/components/MoonBg.vue"

export default {
  components: {
    MoonBg
  },

  props: {
    imgData: {
      type: ImgData,
      required: true
    },
    number: {
      type: Number,
      required: true
    }
  },

  computed: {
    styles() {
      return {
        backgroundImage: `url('${this.imgData.src}')`,
        backgroundPosition: this.number <= 2 ? "right" : "left"
      }
    },
    stylesOverlay() {
      return {
        justifyContent: this.number <= 2 ? "flex-end" : "flex-start"
      }
    }
  }
}
</script>

<styles lang="scss">
@use "bgimg";
@use "gallery-big";
@use "img";
@use "position";
@use "vars";
@use "z-index";

.gallery-big-cut {
  position: relative;
  cursor: pointer;
  @include bgimg.standard;
  overflow: hidden;
}

.gallery-big-cut__overlay {
  position: absolute;
  z-index: z-index.$gallery-big-cut__overlay;
  @include position.all-zeros;
  display: flex;
  align-items: center;
  background-color: img.$overlay--black;
}

.gallery-big-cut__moon {
  margin: gallery-big.$cut-moon-margin;
}

.gallery-big-cut__number {
  width: gallery-big.$cut-moon-dim;
  height: gallery-big.$cut-moon-dim;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: vars.$font-fam-deco;
  color: vars.$brown--black;
}
</styles>
