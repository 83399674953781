<template>
  <div class="gallery-responsive">
    <GalleryBig
      class="gallery-responsive__big"
      :img-arr="imgArr"
    />
    <GallerySmall
      class="gallery-responsive__small"
      :img-arr="imgArr"
    />
  </div>
</template>

<script>
import GalleryBig from "@/components/GalleryBig.vue"
import GallerySmall from "@/components/GallerySmall.vue"
import ImgData from "@/models/ImgData.js"

export default {
  components: {
    GalleryBig,
    GallerySmall
  },

  props: {
    imgArr: {
      type: Array,
      required: true,
      validator: arr => arr.every(x => x instanceof ImgData)
    }
  }
}
</script>

<styles lang="scss">
@use "vars";

.gallery-responsive__big {
  @media (max-width: vars.$break-mobile) {
    display: none;
  }
}

.gallery-responsive__small {
  @media (min-width: vars.$break-mobile) {
    display: none;
  }
}
</styles>
