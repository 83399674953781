<template>
  <div class="gallery-small">
    <!--
      Use :key to re-render ImgCut when imgArr changes.
      Workaround resizing issue when maxRatio increases with new imgArr.
    -->
    <ImgCut
      :key="imgArrID"
      class="gallery-small__bigimg"
      :alt="imgArr[shownIdx].alt"
      :src="imgArr[shownIdx].src"
      :want-ratio="maxRatio"
    />
    <GallerySmallPicrow
      :img-arr="imgArr"
      :shown-idx="shownIdx"
      :want-ratio="wantRatio"
      @gallery-small-picrow-click="setShownIdx"
      @gallery-small-picrow-newratio="onPicrowNewratio"
    />
  </div>
</template>

<script>
import GallerySmallPicrow from "@/components/GallerySmallPicrow.vue"
import ImgCut from "@/components/ImgCut.vue"
import ImgData from "@/models/ImgData.js"

export default {
  components: {
    GallerySmallPicrow,
    ImgCut
  },

  props: {
    imgArr: {
      type: Array,
      required: true,
      validator: arr => arr.every(x => x instanceof ImgData)
    },
    wantRatio: {
      type: Number,
      default: 3 / 2 // most frequent ratio of 5 pics
    }
  },

  data() {
    return {
      maxRatio: this.wantRatio,
      shownIdx: 0
    }
  },

  computed: {
    imgArrID() {
      return ImgData.arrID(this.imgArr)
    }
  },

  watch: {
    imgArr() {
      this.maxRatio = this.wantRatio
      this.shownIdx = 0 // jump to first pic
    }
  },

  methods: {
    onPicrowNewratio(newVal) {
      if (newVal > this.maxRatio) {
        // Cause all img's height to shrink.
        this.maxRatio = newVal
      }
    },

    setShownIdx(i) {
      this.shownIdx = i
    }
  }
}
</script>

<styles lang="scss">
@use "img";

.gallery-small__bigimg {
  padding: img.$gap 0;
  background-color: img.$gap__color;
}
</styles>
